import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../lib/supabase';
import UserProfileModal from './UserProfileModal';

// Füge diese Hilfsfunktion hinzu
const getInitial = (name) => {
  return name ? name.charAt(0).toUpperCase() : '?';
};

const getStoredAvatarUrl = (userId, avatarFile) => {
  const key = `avatar_${userId}_${avatarFile}`;
  const stored = localStorage.getItem(key);
  if (stored) {
    const { url, expiry } = JSON.parse(stored);
    if (expiry > Date.now()) {
      return url;
    }
    localStorage.removeItem(key);
  }
  return null;
};

const preloadImage = (url) => {
  if (!url) return;
  const img = new Image();
  img.src = url;
};

function Sidebar({ user, onLogout, currentPage = 'feed', onPageChange, showNotification }) {
  const [postCount, setPostCount] = useState(0);
  const [username, setUsername] = useState('');
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Separater Effekt für den Post Count
  useEffect(() => {
    const loadPostCount = async () => {
      try {
        const { count, error } = await supabase
          .from('posts')
          .select('*', { count: 'exact' });
          
        if (error) throw error;
        setPostCount(count || 0);
      } catch (error) {
        console.error('Failed to load post count:', error);
      }
    };

    loadPostCount();

    // Realtime subscription für Post-Updates
    const postsChannel = supabase
      .channel('posts_count')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'posts'
      }, loadPostCount)
      .subscribe();

    return () => {
      supabase.removeChannel(postsChannel);
    };
  }, []); // Leeres Dependency Array, da wir den Count immer laden wollen

  // Modifiziere die loadData Funktion und entferne den Post Count Teil
  useEffect(() => {
    const loadData = async () => {
      if (!user) return;
      
      setUsername(user?.user_metadata?.username || user?.email?.split('@')[0] || 'Anonymous');
      
      // Lade Avatar URL
      if (user?.user_metadata?.avatar_file) {
        // Prüfe zuerst den Cache
        const cachedUrl = getStoredAvatarUrl(user.id, user.user_metadata.avatar_file);
        if (cachedUrl) {
          setAvatarUrl(cachedUrl);
          return;
        }

        try {
          const { data, error } = await supabase.storage
            .from('avatars')
            .createSignedUrl(user.user_metadata.avatar_file, 60 * 60 * 24 * 365);
          
          if (error) {
            if (error.message.includes('Object not found')) {
              const { error: updateError } = await supabase.auth.updateUser({
                data: { 
                  avatar_url: null,
                  avatar_file: null
                }
              });
              if (updateError) console.error('Error cleaning metadata:', updateError);
            } else {
              console.error('Error getting signed URL:', error);
            }
            return;
          }

          if (data?.signedUrl) {
            // Cache die URL
            const key = `avatar_${user.id}_${user.user_metadata.avatar_file}`;
            localStorage.setItem(key, JSON.stringify({
              url: data.signedUrl,
              expiry: Date.now() + (60 * 60 * 24 * 364 * 1000) // 364 Tage
            }));
            
            preloadImage(data.signedUrl);
            setAvatarUrl(data.signedUrl);
          }
        } catch (error) {
          console.error('Error loading avatar:', error);
        }
      }
    };

    loadData();
  }, [user]);

  // Realtime presence für Online-User
  useEffect(() => {
    if (!user) return;

    const channel = supabase.channel('online-users', {
      config: {
        presence: {
          key: user.id,
        },
      },
    });

    channel
      .on('presence', { event: 'sync' }, () => {
        const presenceState = channel.presenceState();
        const userList = Object.values(presenceState).map(presence => presence[0]);
        setOnlineUsers(userList);
      })
      .subscribe(async (status) => {
        if (status === 'SUBSCRIBED') {
          await channel.track({
            user_id: user.id,
            username: user?.user_metadata?.username || user?.email?.split('@')[0] || 'Anonymous',
          });
        }
      });

    return () => {
      channel.unsubscribe();
    };
  }, [user]);

  const menuItems = [
    { id: 'feed', label: 'Feed' },
    { id: 'profile', label: 'Edit Profile' },
    { id: 'settings', label: 'Settings' },
  ];

  const handleUserClick = (clickedUserId) => {
    if (!user) {
      showNotification('You need to be logged in to view user profiles', 'error');
      return;
    }
    
    setSelectedUserId(clickedUserId);
  };

  return (
    <motion.div className="w-64 bg-dark-800 h-screen fixed left-0 top-0 p-6 flex flex-col">
      {/* User name at top - mit Avatar */}
      <div className="mb-6">
        <div className="flex items-center space-x-3 mb-4">
          <div className="w-10 h-10 rounded-full bg-accent overflow-hidden flex items-center justify-center text-white font-medium">
            {avatarUrl ? (
              <img 
                src={avatarUrl} 
                alt="Profile" 
                className="w-full h-full object-cover"
                loading="lazy"
                onError={(e) => {
                  e.target.style.display = 'none';
                  e.target.parentElement.textContent = getInitial(username);
                }}
              />
            ) : (
              getInitial(username)
            )}
          </div>
          <div 
            className="text-white text-lg font-medium cursor-pointer hover:text-accent transition-colors"
            onClick={() => handleUserClick(user.id)}
          >
            {username}
          </div>
        </div>
        <div className="border-t border-dark-700" />
      </div>

      {/* Navigation */}
      <div className="flex-1">
        <nav className="space-y-2">
          {menuItems.map((item) => (
            <motion.button
              key={item.id}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ type: "tween", duration: 0.1 }}
              onClick={() => onPageChange(item.id)}
              className={`w-full text-left px-4 py-3 rounded-lg transition-colors ${
                currentPage === item.id
                  ? 'bg-accent text-white'
                  : 'text-gray-400 hover:text-white hover:bg-dark-700'
              }`}
            >
              {item.label}
            </motion.button>
          ))}
        </nav>

        {/* Online Users */}
        <div className="mt-6">
          <div className="border-t border-dark-700 mb-4" />
          <div className="bg-dark-700 rounded-lg p-4">
            <h3 className="text-gray-400 mb-3 flex items-center">
              <span className="mr-2">Online Users</span>
              <span className="w-2 h-2 bg-green-500 rounded-full"></span>
            </h3>
            <div className="space-y-2 max-h-40 overflow-y-auto">
              {onlineUsers.map((onlineUser) => (
                <div 
                  key={onlineUser.user_id} 
                  className={`flex items-center space-x-2 text-sm ${user ? 'cursor-pointer' : ''}`}
                  onClick={() => user && handleUserClick(onlineUser.user_id)}
                >
                  <span className="w-1.5 h-1.5 bg-green-500 rounded-full"></span>
                  <span className={`text-white truncate ${user ? 'hover:underline' : ''}`}>
                    {onlineUser.username}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="mt-6">
          <div className="border-t border-dark-700 mb-4" />
          <div className="bg-dark-700 p-4 rounded-lg">
            <h3 className="text-gray-400 mb-2">Statistics</h3>
            <div className="flex justify-between text-white">
              <span>Total Posts</span>
              <span className="font-bold">{postCount}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Logout button */}
      <div className="mt-6">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onLogout}
          className="w-full px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded-lg transition-colors"
        >
          Logout
        </motion.button>
      </div>

      <AnimatePresence>
        {selectedUserId && (
          <UserProfileModal
            userId={selectedUserId}
            currentUser={user}
            onClose={() => setSelectedUserId(null)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default Sidebar;