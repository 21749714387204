import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../lib/supabase';
import Sidebar from './Sidebar';
import UserProfileModal from './UserProfileModal';
import Comments from './Comments';

const Feed = ({ user, onLogout, showNotification, currentPage, onPageChange }) => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [userLikes, setUserLikes] = useState(new Set());
  const [avatarUrls, setAvatarUrls] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [activeComments, setActiveComments] = useState(null);

  const getStoredAvatarUrl = (userId, avatarFile) => {
    const key = `avatar_${userId}_${avatarFile}`;
    const stored = localStorage.getItem(key);
    if (stored) {
      const { url, expiry } = JSON.parse(stored);
      if (expiry > Date.now()) {
        return url;
      }
      localStorage.removeItem(key);
    }
    return null;
  };

  const loadAvatarUrl = useCallback(async (userId, avatarFile) => {
    if (!avatarFile) return null;
    
    const cachedUrl = getStoredAvatarUrl(userId, avatarFile);
    if (cachedUrl) return cachedUrl;

    try {
      const { data, error } = await supabase.storage
        .from('avatars')
        .createSignedUrl(avatarFile, 60 * 60 * 24 * 365);
      
      if (error) {
        if (error.message.includes('Object not found')) {
          // Avatar-Datei existiert nicht mehr, bereinige die Posts
          const { error: updateError } = await supabase
            .from('posts')
            .update({ avatar_file: null })
            .eq('user_id', userId)
            .eq('avatar_file', avatarFile);
            
          if (updateError) console.error('Error cleaning posts:', updateError);
        } else {
          console.error('Error getting signed URL:', error);
        }
        return null;
      }

      if (data?.signedUrl) {
        const key = `avatar_${userId}_${avatarFile}`;
        localStorage.setItem(key, JSON.stringify({
          url: data.signedUrl,
          expiry: Date.now() + (60 * 60 * 24 * 364 * 1000) // 364 Tage
        }));
        return data.signedUrl;
      }
    } catch (error) {
      console.error('Error loading avatar:', error);
      return null;
    }
  }, []);

  const preloadImage = (url) => {
    if (!url) return;
    const img = new Image();
    img.src = url;
  };

  const fetchPosts = useCallback(async () => {
    try {
      const { data: postsData, error } = await supabase
        .from('posts')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const avatarUrlPromises = postsData.map(async post => {
        if (post.avatar_file) {
          const url = await loadAvatarUrl(post.user_id, post.avatar_file);
          if (url) preloadImage(url);
          return [post.user_id, url];
        }
        return [post.user_id, null];
      });

      const avatarUrlResults = await Promise.all(avatarUrlPromises);
      const newAvatarUrls = Object.fromEntries(avatarUrlResults.filter(([_, url]) => url));
      
      setAvatarUrls(newAvatarUrls);
      setPosts(postsData);
    } catch (error) {
      showNotification(error.message);
    }
  }, [showNotification, loadAvatarUrl]);

  const fetchUserLikes = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('likes')
        .select('post_id')
        .eq('user_id', user.id);

      if (error) throw error;
      setUserLikes(new Set(data.map(like => like.post_id)));
    } catch (error) {
      console.error('Error fetching likes:', error);
    }
  }, [user.id]);

  useEffect(() => {
    fetchPosts();
    fetchUserLikes();
    setUsername(user?.user_metadata?.username || user?.email?.split('@')[0] || 'Anonymous');
  }, [user, fetchPosts, fetchUserLikes]);

  const handleLike = async (postId) => {
    try {
      if (userLikes.has(postId)) {
        const { error } = await supabase
          .from('likes')
          .delete()
          .match({ user_id: user.id, post_id: postId });

        if (error) throw error;
        
        setUserLikes(prev => {
          const newLikes = new Set(prev);
          newLikes.delete(postId);
          return newLikes;
        });
      } else {
        const { error } = await supabase
          .from('likes')
          .insert([{ user_id: user.id, post_id: postId }]);

        if (error) throw error;
        
        setUserLikes(prev => new Set([...prev, postId]));
      }
    } catch (error) {
      showNotification(error.message);
    }
  };

  useEffect(() => {
    const subscription = supabase
      .channel('posts_and_likes')
      .on('postgres_changes', 
        { 
          event: '*', 
          schema: 'public', 
          table: 'posts' 
        }, 
        (payload) => {
          console.log('Change received!', payload);
          switch (payload.eventType) {
            case 'INSERT':
              setPosts(current => {
                const exists = current.some(post => post.id === payload.new.id);
                if (exists) return current;
                
                const updatedPosts = [payload.new, ...current];
                return updatedPosts.sort((a, b) => 
                  new Date(b.created_at) - new Date(a.created_at)
                );
              });
              break;
            case 'DELETE':
              setPosts(current => 
                current.filter(post => post.id !== payload.old.id)
              );
              break;
            case 'UPDATE':
              setPosts(current => 
                current.map(post => 
                  post.id === payload.new.id ? payload.new : post
                ).sort((a, b) => 
                  new Date(b.created_at) - new Date(a.created_at)
                )
              );
              break;
            default:
              break;
          }
        }
      )
      .on('postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'likes'
        },
        (payload) => {
          fetchPosts();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [fetchPosts]);

  const createPost = async (e) => {
    e.preventDefault();
    if (!newPost.trim()) return;

    if (newPost.trim().length < 15) {
      showNotification('Post must be at least 15 characters long', 'error');
      return;
    }

    if (newPost.trim().length > 500) {
      showNotification('Post cannot exceed 500 characters', 'error');
      return;
    }

    setIsLoading(true);
    try {
      const { error } = await supabase
        .from('posts')
        .insert([
          {
            content: newPost.trim(),
            user_id: user.id,
            username: username,
            avatar_file: user.user_metadata.avatar_file
          }
        ]);

      if (error) {
        if (error.message.includes('JWT')) {
          showNotification('Your session has expired. Please log in again.', 'error');
          onLogout();
          return;
        }
        throw error;
      }

      setNewPost('');
      showNotification('Post created successfully!', 'success');
    } catch (error) {
      showNotification(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deletePost = async (postId) => {
    try {
      const { error } = await supabase
        .from('posts')
        .delete()
        .match({ id: postId });

      if (error) throw error;

      showNotification('Post deleted successfully!', 'success');
    } catch (error) {
      showNotification(error.message);
    }
  };

  const getCharCountColor = (length) => {
    if (length < 15) return 'text-red-500';
    return 'text-gray-500';
  };

  // Klick außerhalb schließt das Dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.post-menu')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  const handleUserClick = (clickedUserId) => {
    if (!user) {
      showNotification('You need to be logged in to view user profiles', 'error');
      return;
    }
    
    setSelectedUserId(clickedUserId);
  };

  return (
    <div className="flex min-h-screen bg-dark-900">
      <Sidebar 
        user={user}
        onLogout={onLogout}
        currentPage="feed"
        onPageChange={onPageChange}
        showNotification={showNotification}
      />
      
      <main className="flex-1 ml-64 p-8">
        <div className="max-w-2xl mx-auto">
          {/* Create post form */}
          <form onSubmit={createPost} className="mb-8">
            <div className="bg-dark-800 rounded-lg p-4">
              <div className="relative">
                <textarea
                  value={newPost}
                  onChange={(e) => setNewPost(e.target.value)}
                  placeholder="What's on your mind?"
                  className="w-full bg-dark-700 rounded-lg p-4 text-white placeholder-gray-500 focus:ring-2 focus:ring-accent/50 focus:outline-none resize-none"
                  rows="3"
                  maxLength={500}
                />
                <div className="absolute bottom-2 right-2 text-sm flex items-center space-x-1">
                  <span className={getCharCountColor(newPost.trim().length)}>
                    {newPost.trim().length}
                  </span>
                  <span className="text-gray-500">
                    /500
                  </span>
                </div>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <span className="text-sm text-gray-500">
                  Minimum 15 characters required
                </span>
                <button
                  type="submit"
                  disabled={isLoading || newPost.trim().length < 15 || newPost.trim().length > 500}
                  className="px-6 py-2 bg-accent rounded-lg text-white font-medium hover:bg-accent/90 focus:outline-none focus:ring-2 focus:ring-accent/50 transition-all duration-200 disabled:opacity-50"
                >
                  {isLoading ? 'Posting...' : 'Post'}
                </button>
              </div>
            </div>
          </form>

          {/* Posts list */}
          <AnimatePresence mode="popLayout">
            {posts.map(post => (
              <motion.div
                key={post.id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-dark-800 rounded-lg p-6 mb-4"
              >
                <div className="flex space-x-4">
                  <div className="w-10 h-10 rounded-full bg-accent flex-shrink-0 flex items-center justify-center text-white font-medium overflow-hidden">
                    {avatarUrls[post.user_id] ? (
                      <img 
                        src={avatarUrls[post.user_id]} 
                        alt="Profile" 
                        className="w-full h-full object-cover"
                        loading="lazy"
                        onError={(e) => {
                          e.target.style.display = 'none';
                          e.target.parentElement.textContent = getInitial(post.username);
                        }}
                      />
                    ) : (
                      getInitial(post.username)
                    )}
                  </div>
                  <div className="flex-1">
                    <div className="flex justify-between items-start">
                      <div>
                        <p 
                          className={`text-accent font-medium ${user ? 'cursor-pointer hover:underline' : ''}`}
                          onClick={() => user && handleUserClick(post.user_id)}
                        >
                          {post.username}
                        </p>
                        <p className="text-gray-400 text-sm">
                          {new Date(post.created_at).toLocaleDateString()}
                        </p>
                      </div>
                      {user.id === post.user_id && (
                        <div className="relative post-menu">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveDropdown(activeDropdown === post.id ? null : post.id);
                            }}
                            className="p-1 hover:bg-dark-700 rounded-full transition-colors"
                          >
                            <svg 
                              className="w-5 h-5 text-gray-400 hover:text-white transition-colors" 
                              fill="currentColor" 
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                          </button>
                          
                          <AnimatePresence>
                            {activeDropdown === post.id && (
                              <motion.div
                                initial={{ opacity: 0, scale: 0.95 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.95 }}
                                transition={{ duration: 0.1 }}
                                className="absolute right-0 mt-2 w-48 bg-dark-700 rounded-lg shadow-lg overflow-hidden z-50"
                              >
                                <button
                                  onClick={() => {
                                    deletePost(post.id);
                                    setActiveDropdown(null);
                                  }}
                                  className="w-full px-4 py-2 text-left text-red-500 hover:bg-dark-600 transition-colors flex items-center space-x-2"
                                >
                                  <svg 
                                    className="w-4 h-4" 
                                    fill="currentColor" 
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                  </svg>
                                  <span>Delete post</span>
                                </button>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      )}
                    </div>
                    <p className="text-white mt-4">{post.content}</p>
                    <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-700">
                      <button
                        onClick={() => handleLike(post.id)}
                        className="flex items-center space-x-1 group"
                      >
                        <svg
                          className={`w-6 h-6 transition-colors ${
                            userLikes.has(post.id)
                              ? 'text-accent fill-current'
                              : 'text-gray-400 group-hover:text-accent'
                          }`}
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={userLikes.has(post.id) ? "0" : "2"}
                          fill={userLikes.has(post.id) ? "currentColor" : "none"}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                          />
                        </svg>
                        <span className={`text-sm ${
                          userLikes.has(post.id) ? 'text-accent' : 'text-gray-400 group-hover:text-accent'
                        }`}>
                          {post.likes_count || 0}
                        </span>
                      </button>

                      <button
                        onClick={() => setActiveComments(activeComments === post.id ? null : post.id)}
                        className="flex items-center space-x-1 group"
                      >
                        <svg
                          className={`w-6 h-6 transition-colors ${
                            activeComments === post.id
                              ? 'text-accent'
                              : 'text-gray-400 group-hover:text-accent'
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                          />
                        </svg>
                        <span className={`text-sm ${
                          activeComments === post.id 
                            ? 'text-accent' 
                            : 'text-gray-400 group-hover:text-accent'
                        }`}>
                          {post.comments_count || 0}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <AnimatePresence>
                  {activeComments === post.id && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Comments 
                        postId={post.id}
                        user={user}
                        showNotification={showNotification}
                        onUserClick={handleUserClick}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </main>

      <AnimatePresence>
        {selectedUserId && (
          <UserProfileModal
            userId={selectedUserId}
            currentUser={user}
            onClose={() => setSelectedUserId(null)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Feed;
