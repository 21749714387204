import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { supabase } from '../lib/supabase';

const UserProfileModal = ({ userId, onClose, currentUser }) => {
  const [userData, setUserData] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [postCount, setPostCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getStoredAvatarUrl = (userId, avatarFile) => {
    const key = `avatar_${userId}_${avatarFile}`;
    const stored = localStorage.getItem(key);
    if (stored) {
      const { url, expiry } = JSON.parse(stored);
      if (expiry > Date.now()) {
        return url;
      }
      localStorage.removeItem(key);
    }
    return null;
  };

  const loadUserData = useCallback(async () => {
    try {
      // Hole Benutzerdaten aus der public.users Tabelle
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (userError) throw userError;

      // Hole die Anzahl der Posts des Benutzers
      const { count: postsCount, error: countError } = await supabase
        .from('posts')
        .select('*', { count: 'exact' })
        .eq('user_id', userId);

      if (countError) throw countError;
      
      setPostCount(postsCount || 0);

      if (userData) {
        // Lade Avatar URL
        if (userData.avatar_file) {
          const cachedUrl = getStoredAvatarUrl(userId, userData.avatar_file);
          if (cachedUrl) {
            setAvatarUrl(cachedUrl);
          } else {
            const { data, error: urlError } = await supabase.storage
              .from('avatars')
              .createSignedUrl(userData.avatar_file, 60 * 60 * 24 * 365);

            if (!urlError && data?.signedUrl) {
              // Cache die URL
              const key = `avatar_${userId}_${userData.avatar_file}`;
              localStorage.setItem(key, JSON.stringify({
                url: data.signedUrl,
                expiry: Date.now() + (60 * 60 * 24 * 364 * 1000)
              }));
              
              setAvatarUrl(data.signedUrl);
            }
          }
        }

        setUserData(userData);
      }
    } catch (error) {
      console.error('Error loading user data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
    };
    checkAuth();
  }, []);

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  if (!isAuthenticated) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className="bg-dark-800 rounded-lg p-6 max-w-md w-full text-center"
          onClick={e => e.stopPropagation()}
        >
          <h2 className="text-xl text-white font-semibold mb-4">Login Required</h2>
          <p className="text-gray-400 mb-6">
            You need to be logged in to view user profiles.
          </p>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-accent text-white rounded-lg hover:bg-accent/90 transition-colors"
          >
            Close
          </button>
        </motion.div>
      </motion.div>
    );
  }

  if (isLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
      >
        <div className="text-white">Loading...</div>
      </motion.div>
    );
  }

  if (!userData) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
      >
        <div className="text-white">User not found</div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="bg-dark-800 rounded-lg p-6 max-w-md w-full"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl text-white font-semibold">User Profile</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-6">
          {/* Avatar */}
          <div className="flex justify-center">
            <div className="w-32 h-32 rounded-full bg-accent overflow-hidden flex items-center justify-center text-white text-4xl font-medium">
              {avatarUrl ? (
                <img 
                  src={avatarUrl} 
                  alt="Profile" 
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.target.style.display = 'none';
                    e.target.parentElement.textContent = getInitial(userData.username);
                  }}
                />
              ) : (
                getInitial(userData.username)
              )}
            </div>
          </div>

          {/* User Info */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Username
              </label>
              <div className="bg-dark-700 rounded-lg p-3 text-white">
                {userData.username}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Posts
              </label>
              <div className="bg-dark-700 rounded-lg p-3 text-white">
                {postCount}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Member Since
              </label>
              <div className="bg-dark-700 rounded-lg p-3 text-white">
                {new Date(userData.created_at).toLocaleDateString()}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Last Seen
              </label>
              <div className="bg-dark-700 rounded-lg p-3 text-white">
                {new Date(userData.last_sign_in).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default UserProfileModal; 