import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../lib/supabase';

const AuthForm = ({ onAuthSuccess, showNotification }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) return 'Password must be at least 8 characters long';
    if (!hasUpperCase) return 'Password must contain at least one uppercase letter';
    if (!hasLowerCase) return 'Password must contain at least one lowercase letter';
    if (!hasNumbers) return 'Password must contain at least one number';
    if (!hasSpecialChar) return 'Password must contain at least one special character';

    return '';
  };

  const validateUsername = (username) => {
    if (username.length < 3) return 'Username must be at least 3 characters long';
    if (username.length > 20) return 'Username must be less than 20 characters';
    if (!/^[a-zA-Z0-9._-]+$/.test(username)) return 'Username can only contain letters, numbers, and ._-';
    return '';
  };

  const checkPasswordStrength = (password) => {
    let strength = 0;
    const checks = {
      length: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumbers: /\d/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };

    strength = Object.values(checks).filter(Boolean).length;

    return {
      score: strength,
      checks
    };
  };

  const getStrengthColor = (score) => {
    switch (score) {
      case 0:
      case 1:
        return 'bg-red-500';
      case 2:
      case 3:
        return 'bg-yellow-500';
      case 4:
        return 'bg-green-500';
      case 5:
        return 'bg-accent';
      default:
        return 'bg-gray-500';
    }
  };

  const renderPasswordStrength = () => {
    if (!isLogin && formData.password) {
      const { score, checks } = checkPasswordStrength(formData.password);
      
      return (
        <div className="mt-2 space-y-2">
          <div className="flex gap-1">
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className={`h-1 flex-1 rounded-full transition-all duration-300 ${
                  index < score ? getStrengthColor(score) : 'bg-dark-700'
                }`}
              />
            ))}
          </div>
          <div className="grid grid-cols-2 gap-2 text-xs">
            <div className={`text-gray-400 ${checks.length ? 'text-accent' : ''}`}>
              • 8+ characters
            </div>
            <div className={`text-gray-400 ${checks.hasUpperCase ? 'text-accent' : ''}`}>
              • Uppercase letter
            </div>
            <div className={`text-gray-400 ${checks.hasLowerCase ? 'text-accent' : ''}`}>
              • Lowercase letter
            </div>
            <div className={`text-gray-400 ${checks.hasNumbers ? 'text-accent' : ''}`}>
              • Number
            </div>
            <div className={`text-gray-400 ${checks.hasSpecialChar ? 'text-accent' : ''}`}>
              • Special character
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (isLogin) {
        const { error } = await supabase.auth.signInWithPassword({
          email: formData.email.toLowerCase().trim(),
          password: formData.password
        });

        if (error) throw error;

        showNotification('Login successful!', 'success');
        onAuthSuccess();
      } else {
        // Validierungen
        const passwordError = validatePassword(formData.password);
        if (passwordError) {
          throw new Error(passwordError);
        }

        const usernameError = validateUsername(formData.username);
        if (usernameError) {
          throw new Error(usernameError);
        }

        if (formData.password !== formData.confirmPassword) {
          throw new Error('Passwords do not match');
        }

        const { error } = await supabase.auth.signUp({
          email: formData.email.toLowerCase().trim(),
          password: formData.password,
          options: {
            data: {
              username: formData.username.trim(),
            },
            emailRedirectTo: `${window.location.origin}/auth/callback`
          }
        });

        if (error) throw error;

        showNotification('Account created! Please check your email to confirm your registration.', 'success');
      }
    } catch (err) {
      console.error('Authentication error:', err);
      showNotification(err.message || 'Authentication failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleForm = () => {
    setIsLogin(prev => !prev);
    setFormData({
      email: '',
      username: '',
      password: '',
      confirmPassword: ''
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="max-w-md w-full">
        <AnimatePresence mode="wait">
          <motion.div 
            key={isLogin ? 'login' : 'register'}
            className="bg-dark-800/30 backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <div className="p-8">
              <div className="text-center mb-8">
                <motion.h2 
                  className="text-3xl font-light text-white mb-2"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1, duration: 0.2 }}
                >
                  {isLogin ? 'Welcome back' : 'Create account'}
                </motion.h2>
                <motion.p 
                  className="text-gray-400"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.2 }}
                >
                  {isLogin ? 'Sign in to your account' : 'Join us today'}
                </motion.p>
              </div>

              <form className="space-y-6" onSubmit={handleSubmit}>
                <motion.div 
                  className="space-y-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.2 }}
                >
                  {/* Bei der Registrierung zuerst den Benutzernamen anzeigen */}
                  <AnimatePresence mode="wait">
                    {!isLogin && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <input
                          id="username"
                          name="username"
                          type="text"
                          required
                          value={formData.username}
                          onChange={handleInputChange}
                          className="block w-full px-4 py-3 bg-dark-700/30 rounded-xl border-0 placeholder-gray-500 text-gray-200 focus:ring-2 focus:ring-accent/50 focus:bg-dark-700/50 transition-all duration-200"
                          placeholder="Username"
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <div>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                      className="block w-full px-4 py-3 bg-dark-700/30 rounded-xl border-0 placeholder-gray-500 text-gray-200 focus:ring-2 focus:ring-accent/50 focus:bg-dark-700/50 transition-all duration-200"
                      placeholder="Email address"
                    />
                  </div>

                  <div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      value={formData.password}
                      onChange={handleInputChange}
                      className="block w-full px-4 py-3 bg-dark-700/30 rounded-xl border-0 placeholder-gray-500 text-gray-200 focus:ring-2 focus:ring-accent/50 focus:bg-dark-700/50 transition-all duration-200"
                      placeholder="Password"
                    />
                    {renderPasswordStrength()}
                  </div>

                  <AnimatePresence mode="wait">
                    {!isLogin && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <input
                          id="confirm-password"
                          name="confirmPassword"
                          type="password"
                          required
                          value={formData.confirmPassword}
                          onChange={handleInputChange}
                          className="block w-full px-4 py-3 bg-dark-700/30 rounded-xl border-0 placeholder-gray-500 text-gray-200 focus:ring-2 focus:ring-accent/50 focus:bg-dark-700/50 transition-all duration-200"
                          placeholder="Confirm password"
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>

                <motion.button
                  type="submit"
                  disabled={isLoading}
                  className="w-full px-4 py-3 bg-accent rounded-xl text-white font-medium hover:bg-accent/90 focus:outline-none focus:ring-2 focus:ring-accent/50 transition-all duration-200 disabled:opacity-50 transform hover:scale-[1.02] active:scale-[0.98]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.2 }}
                >
                  {isLoading 
                    ? 'Please wait...' 
                    : (isLogin ? 'Sign in' : 'Create account')}
                </motion.button>

                <motion.div 
                  className="text-center mt-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.2 }}
                >
                  <button
                    type="button"
                    onClick={toggleForm}
                    disabled={isLoading}
                    className="text-sm text-gray-400 hover:text-accent transition-colors disabled:opacity-50"
                  >
                    {isLogin
                      ? "Don't have an account? Register here"
                      : 'Already have an account? Sign in here'}
                  </button>
                </motion.div>
              </form>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AuthForm;
