import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../lib/supabase';
import Sidebar from './Sidebar';
import ImageUploadModal from './ImageUploadModal';

function Profile({ user, onLogout, onPageChange, showNotification }) {
  const [username, setUsername] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  const getStoredAvatarUrl = (userId, avatarFile) => {
    const key = `avatar_${userId}_${avatarFile}`;
    const stored = localStorage.getItem(key);
    if (stored) {
      const { url, expiry } = JSON.parse(stored);
      if (expiry > Date.now()) {
        return url;
      }
      localStorage.removeItem(key);
    }
    return null;
  };

  const preloadImage = (url) => {
    if (!url) return;
    const img = new Image();
    img.src = url;
  };

  const refreshAvatarUrl = useCallback(async () => {
    if (user?.user_metadata?.avatar_file) {
      const cachedUrl = getStoredAvatarUrl(user.id, user.user_metadata.avatar_file);
      if (cachedUrl) {
        setAvatarUrl(cachedUrl);
        return;
      }

      try {
        const { data, error } = await supabase.storage
          .from('avatars')
          .createSignedUrl(user.user_metadata.avatar_file, 60 * 60 * 24 * 365);
        
        if (error) {
          if (error.message.includes('Object not found')) {
            const { error: updateError } = await supabase.auth.updateUser({
              data: { 
                avatar_url: null,
                avatar_file: null
              }
            });
            if (updateError) console.error('Error cleaning metadata:', updateError);
            setAvatarUrl(null);
          } else {
            console.error('Error getting signed URL:', error);
          }
          return;
        }

        if (data?.signedUrl) {
          const key = `avatar_${user.id}_${user.user_metadata.avatar_file}`;
          localStorage.setItem(key, JSON.stringify({
            url: data.signedUrl,
            expiry: Date.now() + (60 * 60 * 24 * 364 * 1000)
          }));
          
          preloadImage(data.signedUrl);
          setAvatarUrl(data.signedUrl);
        }
      } catch (error) {
        console.error('Error refreshing avatar URL:', error);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [user?.user_metadata?.avatar_file, user?.id]);

  useEffect(() => {
    setUsername(user?.user_metadata?.username || user?.email?.split('@')[0] || '');
    refreshAvatarUrl();
  }, [user, refreshAvatarUrl]);

  const validateUsername = (name) => {
    if (name.length < 3) return 'Username must be at least 3 characters';
    if (name.length > 20) return 'Username must be at most 20 characters';
    if (!/^[a-zA-Z0-9._-]+$/.test(name)) return 'Username can only contain letters, numbers, and . _ -';
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateUsername(username);
    if (validationError) {
      showNotification(validationError, 'error');
      return;
    }

    setIsLoading(true);
    try {
      const { error } = await supabase.auth.updateUser({
        data: { username: username.trim() }
      });

      if (error) throw error;
      
      setIsEditing(false);
      showNotification('Username updated successfully!', 'success');
    } catch (err) {
      console.error('Update error:', err);
      showNotification('Failed to update username. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageSave = async (blob) => {
    setIsUploadingImage(true);
    try {
      console.log('Starting upload process...', blob);
      
      // Lösche altes Avatar-Bild falls vorhanden
      if (user?.user_metadata?.avatar_file) {
        const oldFileName = user.user_metadata.avatar_file;
        console.log('Deleting old file:', oldFileName);
        
        const { error: deleteError, data: deleteData } = await supabase.storage
          .from('avatars')
          .remove([oldFileName]);

        if (deleteError) {
          console.error('Error deleting old avatar:', deleteError);
        } else {
          console.log('Successfully deleted old avatar:', deleteData);
        }
      }

      // Upload to Supabase Storage mit Benutzer-ID als Ordner
      const fileName = `${user.id}/avatar-${Date.now()}.jpg`;  // Datei im User-Ordner speichern
      console.log('Uploading new file:', fileName);
      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(fileName, blob, {
          contentType: 'image/jpeg',
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);
        throw uploadError;
      }

      // Get authenticated URL
      const { data: { signedUrl } } = await supabase.storage
        .from('avatars')
        .createSignedUrl(fileName, 60 * 60 * 24 * 365);

      console.log('Got signed URL:', signedUrl);

      // Update user metadata (der Trigger kümmert sich um die users Tabelle)
      const { error: updateError } = await supabase.auth.updateUser({
        data: { 
          avatar_url: signedUrl,
          avatar_file: fileName
        }
      });

      if (updateError) throw updateError;

      // Update posts
      const { error: postsError } = await supabase
        .from('posts')
        .update({ avatar_file: fileName })
        .eq('user_id', user.id);

      if (postsError) throw postsError;

      setAvatarUrl(signedUrl);
      setShowImageUpload(false);
      showNotification('Profile picture updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating profile picture:', error);
      showNotification('Failed to update profile picture: ' + error.message, 'error');
    } finally {
      setIsUploadingImage(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-dark-900">
      <Sidebar 
        user={user}
        onLogout={onLogout}
        currentPage="profile"
        onPageChange={onPageChange}
        showNotification={showNotification}
      />
      
      <main className="flex-1 ml-64 p-8">
        <div className="max-w-2xl mx-auto">
          <div className="bg-dark-800 rounded-lg overflow-hidden">
            {/* Header */}
            <div className="bg-dark-800 px-6 py-4 border-b border-dark-700">
              <h2 className="text-xl text-white font-semibold">Profile Settings</h2>
            </div>

            {/* Content */}
            <div className="p-6 space-y-6">
              {/* Avatar Section */}
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Profile Picture
                </label>
                <div className="flex items-center space-x-4">
                  <div className="w-20 h-20 rounded-full bg-accent overflow-hidden flex items-center justify-center text-white font-medium">
                    {avatarUrl ? (
                      <img 
                        src={avatarUrl} 
                        alt="Profile" 
                        className="w-full h-full object-cover"
                        loading="lazy"
                        onError={(e) => {
                          e.target.style.display = 'none';
                          e.target.parentElement.textContent = getInitial(username);
                        }}
                      />
                    ) : (
                      getInitial(username)
                    )}
                  </div>
                  <button
                    onClick={() => setShowImageUpload(true)}
                    disabled={isUploadingImage}
                    className="px-4 py-2 bg-dark-700 text-white rounded-lg hover:bg-dark-600 transition-colors text-sm disabled:opacity-50 flex items-center space-x-2"
                  >
                    {isUploadingImage ? (
                      <>
                        <svg 
                          className="animate-spin h-4 w-4 text-white" 
                          xmlns="http://www.w3.org/2000/svg" 
                          fill="none" 
                          viewBox="0 0 24 24"
                        >
                          <circle 
                            className="opacity-25" 
                            cx="12" 
                            cy="12" 
                            r="10" 
                            stroke="currentColor" 
                            strokeWidth="4"
                          />
                          <path 
                            className="opacity-75" 
                            fill="currentColor" 
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        <span>Uploading...</span>
                      </>
                    ) : (
                      <span>Upload Profile Picture</span>
                    )}
                  </button>
                </div>
              </div>

              {/* Username Section */}
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Username
                </label>
                {isEditing ? (
                  <div className="space-y-3">
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="w-full px-4 py-2 bg-dark-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-accent border border-dark-600"
                      placeholder="Enter new username"
                      disabled={isLoading}
                    />
                    <div className="text-sm text-gray-500">
                      Username must be 3-20 characters and can only contain letters, numbers, and . _ -
                    </div>
                    <div className="flex space-x-3">
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        type="submit"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        className="px-4 py-2 bg-accent text-white rounded-lg hover:bg-accent/90 transition-colors disabled:opacity-50"
                      >
                        {isLoading ? 'Saving...' : 'Save Changes'}
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        type="button"
                        onClick={() => {
                          setIsEditing(false);
                          setUsername(user?.user_metadata?.username || user?.email?.split('@')[0] || '');
                        }}
                        disabled={isLoading}
                        className="px-4 py-2 bg-dark-700 text-white rounded-lg hover:bg-dark-600 transition-colors disabled:opacity-50"
                      >
                        Cancel
                      </motion.button>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between p-4 bg-dark-700 rounded-lg border border-dark-600">
                    <span className="text-white">{username}</span>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      type="button"
                      onClick={() => setIsEditing(true)}
                      className="px-4 py-2 bg-dark-600 text-white rounded-lg hover:bg-dark-500 transition-colors"
                    >
                      Edit
                    </motion.button>
                  </div>
                )}
              </div>

              {/* Email Section */}
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Email
                </label>
                <div className="p-4 bg-dark-700 rounded-lg border border-dark-600">
                  <span className="text-white">{user?.email}</span>
                </div>
              </div>

              {/* Account Info */}
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Account Created
                </label>
                <div className="p-4 bg-dark-700 rounded-lg border border-dark-600">
                  <span className="text-white">
                    {new Date(user?.created_at).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <AnimatePresence>
        {showImageUpload && (
          <ImageUploadModal
            onClose={() => setShowImageUpload(false)}
            onSave={handleImageSave}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default Profile;