import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../lib/supabase';

const Comments = ({ postId, user, showNotification, onUserClick }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [avatarUrls, setAvatarUrls] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  const getStoredAvatarUrl = (userId, avatarFile) => {
    const key = `avatar_${userId}_${avatarFile}`;
    const stored = localStorage.getItem(key);
    if (stored) {
      const { url, expiry } = JSON.parse(stored);
      if (expiry > Date.now()) {
        return url;
      }
      localStorage.removeItem(key);
    }
    return null;
  };

  const loadAvatarUrl = useCallback(async (userId, avatarFile) => {
    if (!avatarFile) return null;
    
    const cachedUrl = getStoredAvatarUrl(userId, avatarFile);
    if (cachedUrl) return cachedUrl;

    try {
      const { data, error } = await supabase.storage
        .from('avatars')
        .createSignedUrl(avatarFile, 60 * 60 * 24 * 365);
      
      if (error) throw error;

      if (data?.signedUrl) {
        const key = `avatar_${userId}_${avatarFile}`;
        localStorage.setItem(key, JSON.stringify({
          url: data.signedUrl,
          expiry: Date.now() + (60 * 60 * 24 * 364 * 1000)
        }));
        return data.signedUrl;
      }
    } catch (error) {
      console.error('Error loading avatar:', error);
      return null;
    }
  }, []);

  const loadComments = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('comments')
        .select('*')
        .eq('post_id', postId)
        .order('created_at', { ascending: true });

      if (error) throw error;

      // Lade Avatar URLs für alle Kommentare
      const avatarPromises = data.map(async comment => {
        if (comment.avatar_file) {
          const url = await loadAvatarUrl(comment.user_id, comment.avatar_file);
          return [comment.user_id, url];
        }
        return [comment.user_id, null];
      });

      const avatarResults = await Promise.all(avatarPromises);
      const newAvatarUrls = Object.fromEntries(avatarResults.filter(([_, url]) => url));
      
      setAvatarUrls(newAvatarUrls);
      setComments(data || []);
    } catch (error) {
      console.error('Error loading comments:', error);
    }
  }, [postId, loadAvatarUrl]);

  useEffect(() => {
    loadComments();

    // Realtime subscription für Kommentare
    const commentsChannel = supabase
      .channel(`comments-${postId}`)
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'comments',
        filter: `post_id=eq.${postId}`
      }, loadComments)
      .subscribe();

    return () => {
      supabase.removeChannel(commentsChannel);
    };
  }, [postId, loadComments]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('comments')
        .insert([{
          content: newComment.trim(),
          post_id: postId,
          user_id: user.id,
          username: user.user_metadata.username || user.email.split('@')[0],
          avatar_file: user.user_metadata.avatar_file
        }])
        .select()
        .single();

      if (error) throw error;

      // Optimistische UI-Aktualisierung
      if (data) {
        setComments(prev => [...prev, data]);
        if (data.avatar_file) {
          const url = await loadAvatarUrl(data.user_id, data.avatar_file);
          if (url) {
            setAvatarUrls(prev => ({
              ...prev,
              [data.user_id]: url
            }));
          }
        }
      }

      setNewComment('');
      showNotification('Comment added successfully!', 'success');
    } catch (error) {
      showNotification(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const { error } = await supabase
        .from('comments')
        .delete()
        .match({ id: commentId });

      if (error) throw error;

      // Optimistische UI-Aktualisierung
      setComments(prev => prev.filter(comment => comment.id !== commentId));
      showNotification('Comment deleted successfully!', 'success');
    } catch (error) {
      showNotification(error.message, 'error');
    }
  };

  // Klick außerhalb schließt das Dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.comment-menu')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="mt-4 space-y-4">
      {/* Kommentar-Liste */}
      <div className="space-y-3">
        <AnimatePresence mode="popLayout">
          {comments.map(comment => (
            <motion.div
              key={comment.id}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex space-x-3 bg-dark-700 p-3 rounded-lg"
            >
              <div className="w-8 h-8 rounded-full bg-accent flex-shrink-0 flex items-center justify-center text-white font-medium overflow-hidden">
                {avatarUrls[comment.user_id] ? (
                  <img 
                    src={avatarUrls[comment.user_id]} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                    loading="lazy"
                    onError={(e) => {
                      e.target.style.display = 'none';
                      e.target.parentElement.textContent = getInitial(comment.username);
                    }}
                  />
                ) : (
                  getInitial(comment.username)
                )}
              </div>
              <div className="flex-1">
                <div className="flex justify-between items-start">
                  <div>
                    <p 
                      className="text-accent font-medium text-sm cursor-pointer hover:underline"
                      onClick={() => onUserClick(comment.user_id)}
                    >
                      {comment.username}
                    </p>
                    <p className="text-white text-sm">{comment.content}</p>
                  </div>
                  {user.id === comment.user_id && (
                    <div className="relative comment-menu">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveDropdown(activeDropdown === comment.id ? null : comment.id);
                        }}
                        className="p-1 hover:bg-dark-600 rounded-full transition-colors"
                      >
                        <svg 
                          className="w-4 h-4 text-gray-400 hover:text-white transition-colors" 
                          fill="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                        </svg>
                      </button>

                      <AnimatePresence>
                        {activeDropdown === comment.id && (
                          <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            transition={{ duration: 0.1 }}
                            className="absolute right-0 mt-2 w-48 bg-dark-600 rounded-lg shadow-lg overflow-hidden z-50"
                          >
                            <button
                              onClick={() => {
                                handleDelete(comment.id);
                                setActiveDropdown(null);
                              }}
                              className="w-full px-4 py-2 text-left text-red-500 hover:bg-dark-500 transition-colors flex items-center space-x-2"
                            >
                              <svg 
                                className="w-4 h-4" 
                                fill="currentColor" 
                                viewBox="0 0 24 24"
                              >
                                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                              </svg>
                              <span>Delete comment</span>
                            </button>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  )}
                </div>
                <p className="text-gray-400 text-xs mt-1">
                  {new Date(comment.created_at).toLocaleDateString()}
                </p>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {/* Kommentar-Formular */}
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="flex space-x-2">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write a comment..."
            className="flex-1 bg-dark-700 text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-accent"
            maxLength={500}
          />
          <button
            type="submit"
            disabled={isLoading || !newComment.trim()}
            className="px-4 py-2 bg-accent text-white rounded-lg hover:bg-accent/90 transition-colors disabled:opacity-50"
          >
            {isLoading ? 'Posting...' : 'Post'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Comments; 