import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Notification = ({ message, type = 'error', onClose }) => {
  if (!message) return null;

  const bgColor = {
    error: 'bg-red-500/10 text-red-400 border-red-500/20',
    success: 'bg-green-500/10 text-green-400 border-green-500/20',
    info: 'bg-blue-500/10 text-blue-400 border-blue-500/20',
  }[type];

  return (
    <AnimatePresence>
      {message && (
        <div className="fixed top-0 left-0 right-0 flex justify-center z-50 pointer-events-none">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 20 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="pointer-events-auto"
          >
            <div className={`rounded-xl px-6 py-3 border backdrop-blur-lg shadow-lg ${bgColor}`}>
              <div className="flex items-center space-x-4">
                <span>{message}</span>
                <button
                  onClick={onClose}
                  className="hover:opacity-70 transition-opacity"
                >
                  ✕
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Notification;
