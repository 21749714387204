import { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from './lib/supabase';
import AuthForm from './components/AuthForm';
import Feed from './components/Feed';
import Profile from './components/Profile';
import Notification from './components/Notification';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState('home');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('success');

  useEffect(() => {
    // Prüfe den initialen Auth-Status
    supabase.auth.getSession().then(({ data: { session } }) => {
      setIsAuthenticated(!!session);
      setUser(session?.user || null);
    });

    // Abonniere Auth-Änderungen
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setIsAuthenticated(!!session);
      setUser(session?.user || null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleNotification = (message, type = 'error') => {
    setNotificationMessage(message);
    setNotificationType(type);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const handleAuthSuccess = () => {
    setIsAuthenticated(true);
    handleNotification('Successfully logged in!', 'success');
  };

  const handleLogout = async () => {
    try {
      // Beende alle Realtime-Subscriptions
      await supabase.channel('custom-all-channel').unsubscribe();
      
      // Lokalen Storage leeren und ausloggen
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // States zurücksetzen
      setIsAuthenticated(false);
      setUser(null);
      setCurrentPage('home');
      
      // Browser Storage leeren
      localStorage.clear();
      sessionStorage.clear();

      handleNotification('Successfully logged out!', 'success');
    } catch (error) {
      console.error('Logout error:', error);
      handleNotification('Error during logout. Please try again.', 'error');
    }
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const renderContent = () => {
    switch (currentPage) {
      case 'profile':
        return (
          <Profile 
            user={user}
            showNotification={handleNotification}
            onPageChange={handlePageChange}
            onLogout={handleLogout}
          />
        );
      case 'home':
      default:
        return (
          <Feed 
            user={user}
            onLogout={handleLogout}
            showNotification={handleNotification}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        );
    }
  };

  return (
    <div className="min-h-screen bg-dark-900">
      {showNotification && (
        <Notification
          message={notificationMessage}
          type={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
      
      <AnimatePresence mode="wait">
        {!isAuthenticated ? (
          <motion.div
            key="auth"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <AuthForm onAuthSuccess={handleAuthSuccess} showNotification={handleNotification} />
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {renderContent()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;
