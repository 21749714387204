import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { motion } from 'framer-motion';

const ImageUploadModal = ({ onClose, onSave }) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 200,
    height: 200,
    x: 0,
    y: 0
  });
  const [imageRef, setImageRef] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.size > 2 * 1024 * 1024) {
      alert('Image must be smaller than 2MB');
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => setImage(reader.result));
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    maxSize: 2 * 1024 * 1024,
    multiple: false
  });

  const getCroppedImg = useCallback(async () => {
    if (!imageRef || !completedCrop) {
      console.log('Missing imageRef or completedCrop:', { imageRef, completedCrop });
      return null;
    }

    try {
      const canvas = document.createElement('canvas');
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = 200;
      canvas.height = 200;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        console.error('No 2d context');
        return null;
      }

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(
        imageRef,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        200,
        200
      );

      const croppedUrl = canvas.toDataURL('image/jpeg', 0.95);
      setCroppedImage(croppedUrl);
      return croppedUrl;
    } catch (err) {
      console.error('Error in getCroppedImg:', err);
      return null;
    }
  }, [imageRef, completedCrop]);

  const handleSave = async () => {
    if (!croppedImage || isUploading) return;
    
    setIsUploading(true);
    try {
      const blob = await fetch(croppedImage).then(r => r.blob());
      await onSave(blob);
    } catch (error) {
      console.error('Error saving image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="bg-dark-800 rounded-lg p-6 max-w-lg w-full"
      >
        <h3 className="text-xl text-white font-semibold mb-4">Upload Profile Picture</h3>
        
        {!image ? (
          <div
            {...getRootProps()}
            className={`border-2 border-dashed border-dark-600 rounded-lg p-8 text-center cursor-pointer hover:border-accent transition-colors
              ${isDragActive ? 'border-accent bg-accent/10' : ''}`}
          >
            <input {...getInputProps()} />
            <p className="text-gray-400">
              {isDragActive
                ? "Drop the image here"
                : "Drag & drop an image here, or click to select"}
            </p>
            <p className="text-sm text-gray-500 mt-2">Maximum size: 2MB</p>
          </div>
        ) : (
          <div className="space-y-4">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={async (c) => {
                console.log('Crop completed:', c);
                setCompletedCrop(c);
                await getCroppedImg();
              }}
              aspect={1}
              circularCrop
            >
              <img 
                src={image} 
                alt="Upload" 
                className="max-h-[400px] rounded-lg"
                onLoad={(e) => {
                  console.log('Image onLoad event:', e);
                  const img = e.currentTarget;
                  setImageRef(img);
                  
                  const width = Math.min(img.width, img.height);
                  const x = (img.width - width) / 2;
                  const y = (img.height - width) / 2;
                  
                  const newCrop = {
                    unit: 'px',
                    width,
                    height: width,
                    x,
                    y
                  };
                  
                  console.log('Setting new crop:', newCrop);
                  setCrop(newCrop);
                  setCompletedCrop(newCrop);
                }}
              />
            </ReactCrop>
            <div className="text-sm text-gray-400 text-center">
              Drag to adjust the crop area
            </div>
          </div>
        )}

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={isUploading}
            className="px-4 py-2 bg-dark-700 text-white rounded-lg hover:bg-dark-600 transition-colors disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={!croppedImage || isUploading}
            className="px-4 py-2 bg-accent text-white rounded-lg hover:bg-accent/90 transition-colors disabled:opacity-50 flex items-center space-x-2"
          >
            {isUploading ? (
              <>
                <svg 
                  className="animate-spin h-5 w-5 text-white" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24"
                >
                  <circle 
                    className="opacity-25" 
                    cx="12" 
                    cy="12" 
                    r="10" 
                    stroke="currentColor" 
                    strokeWidth="4"
                  />
                  <path 
                    className="opacity-75" 
                    fill="currentColor" 
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Saving...</span>
              </>
            ) : (
              <span>Save</span>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ImageUploadModal; 